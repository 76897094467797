<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="faqA">
   <p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><u><span style='font-size:21px; '>أسئلة</span></u></strong><strong><u><span style='font-size:21px; '>&nbsp;شائعة</span></u></strong><strong><span dir="LTR" style='font-size:21px; '>:</span></strong></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>هل يمكنني حجز موعد أو عملية جراحية من خلال الموقع الالكتروني دون أن أسجل نفسي كمريض؟</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'><span style=' font-size:14.0pt;'>يجب تسجيل نفسك كمريض من خلال الموقع قبل حجز أي موعد أو عملية جراحية أو علاج.</span></li>
    </ul>
</div>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>لماذا يجب أن أضع معلومات عن تاريخي الطبي؟</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'><span style=' font-size:14.0pt;'>التاريخ الطبي للمريض من البيانات الهامة جداً لأي طبيب حتى يتمكن من تقييم الحالة ووصف الأدوية وتقدير أي مخاطر قد تحدث وقت إجراء العملية الجراحية</span><span dir="LTR" style='  font-size:14.0pt;'>.</span></li>
    </ul>
</div>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>من الذي يطلع على تاريخي الطبي وفحوصاتي وتحاليلي؟</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'><span style=' font-size:14.0pt;'>يتم التعامل مع جميع مستنداتك الطبية وفقًا للائحــة النظـــام الأوروبي العــــام لحماية البيانات</span><span dir="LTR" style=' font-size:14.0pt;'>&nbsp;(EU GDPR)</span><span >&nbsp;ولن يطلع عليها إلا الأطباء الذين&nbsp;</span><span style=' font-size:14.0pt; '>يقومون بإجراء الإس</span><span style=' font-size:14.0pt; '>تشارة الطبية أو العلاج الطبي</span><span dir="LTR" style=' font-size:14.0pt; '>.</span></li>
    </ul>
</div>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>هل يمكنني حجز موعداً أو عملية جراحية لأفراد عائلتي من خلال الموقع الالكتروني ؟</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'><span style=' font-size:14.0pt;'>يمكنك إدخال أفراد عائلتك بملفك الشخصي كمستخدمين فرعيين لحجز المواعيد لهم أو العمليات الجراحية &nbsp;أو العلاجات من خلال الموقع الالكتروني</span><span dir="LTR" style=' font-size:14.0pt; '>.</span></li>
    </ul>
</div>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>هل يمكنني حجز موعداً من خلال الموقع الالكتروني ودفع قيمته لاحقاً؟</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'><span style=' font-size:14.0pt;'>يمكنك حجز موعدك والدفع لاحقًا من خلال الموقع الالكتروني . نحتفظ بالموعد الذي تم حجزه من خلال&nbsp;</span><span style='  font-size:14.0pt;'>اختيار</span><span style=' font-size:14.0pt; '>&nbsp;&quot;الدفع لاحقاً&quot; لمدة 4 ساعات. في حالة عدم تنفيذ الدفع خلال الـ 4 ساعات التالية ، يتم إلغاء الموعد ليتمكن مريض آخر من حجزه</span><span dir="LTR" style=' font-size:14.0pt; '>.</span></li>
    </ul>
</div>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>هل يمكنني مشاركة التقارير الطبية والاختبارات والتحاليل مع الطبيب دون تحميلها وقت الحجز؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>يقوم الطبيب بمراجعة &nbsp;التقارير الطبية أو التحاليل أو الفحوصات أو أي مستندات مرفقة قبل وقت الاستشارة لتوفير الوقت أثناء مكالمة الفيديو</span><span dir="LTR" style=' font-size:14.0pt; '>.</span></li>
    <li><span style=' font-size:14.0pt;'>في حالة احتياج الطبيب للعودة إلى أي من هذه المستندات،&nbsp;</span><span style="font-size:14.0pt;;">سيتمكن من&nbsp;</span><span style="font-size:14.0pt;;">الوصول إليها من خلال&nbsp;</span><span style=' font-size:14.0pt;'>ال</span><span style=' font-size:14.0pt; '>تاريخ الطبي الذي أرسلته له&nbsp;</span><span dir="LTR" style=' font-size:14.0pt; '>.</span></li>
    <li><span style=' font-size:14.0pt;'>إن حجب المستندات يعرقل مراحل التقييم أو التشخيص المناسب التي يحدده الطبيب.</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;margin-right:.5in;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>هل يمكن ان أعدل أو ألغي موعدي اللإلكتروني؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>من تاريخ الحجز إلى أسبوع واحد قبل الاستشارة عبر الإنترنت&nbsp; الإلغاء مجاني
من 6 أيام إلى 72 ساعة: يتم استرداد 50٪ من المبلغ المدفوع
من 72 إلى تاريخ الاستشارة: غير قابل للاسترداد</span><span dir="LTR" style=' font-size:14.0pt; '>.</span></li>

    <li><span style=' font-size:14.0pt;'>يمكنك تعديل موعدك بالموقع الالكتروني مع نفس الطبيب &nbsp;دون أي رسوم إضافية</span><span dir="LTR" style='  font-size:14.0pt;'>.</span></li>
    <li><span style=' font-size:14.0pt;'>في حالة رغبتك في تغيير الموعد إلى طبيب آخر، يرجى الرجوع إلى وكلاء خدمة المرضى من خلال&nbsp;</span><span dir="LTR" style="font-size:14.0pt;;">&nbsp;</span><span dir="LTR" style=' font-size:14.0pt; '>patient.service@doclinia.com</span></li>
    <li><span style=' font-size:14.0pt;'>في حالة الرغبة في إلغاء&nbsp;</span><span style='  font-size:14.0pt;'>موعدك</span><span style='  font-size:14.0pt;'>&nbsp;، يرجى التحقق من شروط الإلغاء الموضحة مسبقاً في وقت الحجز أو الرجوع إلى وكلاء خدمة المرضى من خلال&nbsp;</span><span dir="LTR" style="font-size:14.0pt;;">&nbsp;</span><span dir="LTR" style=' font-size:14.0pt; '>patient.service@doclinia.com</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>كيف يمكنني حجز موعداً للمتابعة من خلال الموقع الالكتروني؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>في أثناء موعدك الالكتروني ، يحدد طبيبك مدى حاجتك إلى موعد آخر للمتابعة وإبلاغك بالتاريخ التقريبي</span><span dir="LTR" style=' font-size:14.0pt;'>.</span></li>
    <li><span style=' font-size:14.0pt;'>يحدد الطبيب موعداً لك بالموقع الالكتروني وستتلقى إخطاراً على ملفك الشخصي لحجز الموعد التالي مع نفس الطبيب من خلال الانترنت.</span>
        <ol style="list-style-type: circle;">
            <li><span style='  font-size:14.0pt;'>يمكنك الدخول على &quot;تكرار الاستشارة الالكترونية&quot; ، واختيار بند &quot;الموعد الالكتروني المستنفد&quot; للدخول الى الجدول الزمني لنفس الطبيب حتى تتمكن من حجز موعد المتابعة.</span></li>
        </ol>
    </li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>كيف يمكنني حجز عملية جراحية؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>في حالة معرفة نوع العملية الجراحية التي تريدها، يجب الدخول على خانة &quot;مستشفيات&quot; وإختيار&nbsp;</span><span style=' font-size:14.0pt; '>بند&nbsp;</span><span style='  font-size:14.0pt;'>&quot;التخصص&quot; &nbsp;ثم &quot;نوع الجراحة&quot; التي ترغب في حجز موعداً لها.</span></li>
    <li><span style=' font-size:14.0pt;'>في حالة عدم التأكد من نوع الجراحة التي تحتاجها ، يرجى التواصل مع أحد وكلاء خدمة المرضى من خلال&nbsp;</span><span dir="LTR" style='  font-size:14.0pt;'>patient.service@doclinia.com</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>كيف أعرف&nbsp;</span></strong><strong><span style='font-size:19px; '>نوع&nbsp;</span></strong><strong><span style='font-size:19px; '>الجراحة المناسبة لحالتي الطبية؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>في حالة حصولك على تقرير طبي من طبيب محلي يحدد نوع الجراحة المطلوبة، يمكنك مواصلة الحجز.</span></li>
    <li><span style=' font-size:14.0pt;'>في حالة عدم التأكد من نوع الجراحة أو العلاج الصحيح ، يرجى التواصل مع أحد الاستشاريين من خلال البريد الإلكتروني:&nbsp;</span><span dir="LTR" style=' font-size:14.0pt; '>patient.service@doclinia.com</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>لماذا أقوم بدفع رسوم ال</span></strong><strong><span style='font-size:19px; '>تجهيز&nbsp;</span></strong><strong><span style='font-size:19px; '>للعملية الجراحية وهل هي قابلة للاسترداد؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>رسوم التجهيز هي رسوم خاصة بالتنظيم والتنسيق مع المستشفى والأطباء وإعداد الترجمة اللازمة لمستنداتك بالإضافة إلى تأكيد التاريخ المطلوب.</span></li>
    <li><span style=' font-size:14.0pt;'>في حالة عدم قبول المستشفى لإجراء ا</span><span style=' font-size:14.0pt; '>لعملية الجراحية&nbsp;</span><span style='  font-size:14.0pt;'>&nbsp;أو إذا كنت ترغب في إلغائها ، يتم رد المبلغ المدفوع لك بعد&nbsp;</span><span style=' font-size:14.0pt; '>خصم&nbsp;</span><span style='  font-size:14.0pt;'>رسوم الكتابات الورقية والأعمال التنظيمية.</span></li>
    <li><span style=' font-size:14.0pt;'>لمزيد من التفاصيل يمكنك التواصل مع وكلاء خدمة المرضى من خلال</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span style='font-size:19px; '>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span dir="LTR" style='font-size:19px; '>patient.service@doclinia.com</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>كيف يمكنني دفع المبلغ المتبقي للعملية الجراحية؟</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'><span style=' font-size:14.0pt;'>تبعاً للوائح كل بلد ، يقوم قسم وكلاء خدمة المرضى بإبلاغك بالحسابات المصرفية الخاصة بالمستشفى التي يتم بها الحجز وشروط الدفع المتعلقة بعمليتك الجراحية.</span></li>
    </ul>
</div>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>هل يمكنكم حجز رحلة الطيران والفندق من أجلي؟</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'><span style=' font-size:14.0pt;'>يمكننا حجز رحلة الطيران والفندق من أجلك. يُرجى التواصل مع مستشاري السفر من خلال&nbsp;</span><span dir="LTR" style='  font-size:14.0pt;'>travel@doclinia.com</span></li>
    </ul>
</div>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>ماذا أفعل للحصول على تأشيرة عاجلة؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>يقوم قسم وكلاء خدمة المرضى بالمساعدة من خلال تقديم خطاب رسمي من المستشفى لقنصلية الدولة.</span></li>
    <li><span style=' font-size:14.0pt;'>يُرجى مراجعة مستشارينا&nbsp;</span><span style=' font-size:14.0pt; '>&nbsp;بخصوص&nbsp;</span><span style=' font-size:14.0pt; '>جميع الشكوك المتعلقة بإصدار التأشيرات.</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>كيف&nbsp;</span></strong><strong><span style='font-size:19px; '>يمكنني أن&nbsp;</span></strong><strong><span style='font-size:19px; '>أعرف أفضل مستشفى وأفضل وجهة سفر بالنسبة لحالتي الطبية؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>يوجد دليل في المرشح الخاص بالموقع بصفحة النتائج مع توصياتنا بشأن أفضل الوجهات أو المستشفيات لنوع الجراحة المطلوبة.</span></li>
    <li><span style=' font-size:14.0pt;'>يتم إرشادك أيضاً من خلال التصنيف الموجود لكل مستشفى.</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>ماذا ينبغي أخذه في الاعتبار عند حجز عملية جراحية أو علاج ؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>يجب التأكد من حصولك على تأشيرة صالحة للبلد المختار.</span></li>
    <li><span style=' font-size:14.0pt;'>في حالة الاحتياج لإصدار تأشيرة، يُرجى حساب مدة شهر واحد للتواريخ المختارة.</span></li>
    <li><span style=' font-size:14.0pt;'>في حالة وجود أى شكوك حول إجراءات التأشيرة، يُرجى التواصل مع وكلاء خدمة المرضى من خلال البريد الإلكتروني.</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>ما هي تكلفة العلاج بالخارج إذا اصطحبت</span></strong><strong><span style='font-size:19px; '>&nbsp;</span></strong><strong><span style='font-size:19px; '>معي</span></strong><strong><span style='font-size:19px; '>&nbsp;</span></strong><strong><span style='font-size:19px; '>مرافقاً</span></strong><strong><span style='font-size:19px; '>&nbsp;؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>لكل دولة سياستها الخاصة عندما يتعلق الأمر بالمرافقين في المستشفى.</span></li>
    <li><span style=' font-size:14.0pt;'>في حالة الرغبة في حجز ترتيبات الإقامة والإنتقالات للمرافق ، يرجى التواصل مع مستشاري السفر من خلال&nbsp;</span><span dir="LTR" style='  font-size:14.0pt;'>travel@doclinia.com</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>هل يمكن الحصول على خصم إضافي على العمليات الجراحية والعلاجات؟</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'><span style=' font-size:14.0pt;'>للحالات الإنسانية ، يُرجى التواصل مع وكلاء خدمة المرضى.</span></li>
    </ul>
</div>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>هل يمكنني الاتصال بالمستشفى مباشرةً؟</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'><span style=' font-size:14.0pt;'>بعد اتمام الحجز ولطلب أي معلومات إضافية ، ننصحك بالتواصل مع وكلاء خدمة المرضى لتفادي&nbsp;</span><span style='  font-size:14.0pt;'>عقبات اللغة والبروتوكولات الخاصة بالمستشفيات.</span></li>
    </ul>
</div>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>هل استشارة الطبيب الجراح الخاص بي من خلال الموقع الالكتروني عليها رسوم إضافية؟</span></strong></p>
<div style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'>
    <ul style="margin-bottom:0in;list-style-type: disc;">
        <li style='margin-top:0in;margin-right:0in;margin-bottom:10.0pt;margin-left:0in;line-height:115%;font-size:15px;;'><span style=' font-size:14.0pt;'>الحجز الخاص بالعملية الجراحية يشمل استشارة الكترونية مجانية مع الطبيب الجراح الخاص بك.</span></li>
    </ul>
</div>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>ما هي الضمانات التي تقدمونها لي؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>العَرض المقدم من&nbsp;</span><span style='  font-size:14.0pt;'>المستشفى هو بمثابة وثيقة رسمية من المستشفى لسعر العملية الجراحية وقد يتم طلب رسوم إضافية في حالة حدوث تعقيدات.</span></li>
    <li><span style=' font-size:14.0pt;'>في حالة وجود شكوك حول ما سبق، يرجى التواصل مع وكلاء خدمة المرضى.</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>هل سعر العملية الجراحية ثابت؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>جميع أسعار العمليات الجراحية هي أسعار إرشادية.</span></li>
    <li><span style=' font-size:14.0pt;'>يتم إرسال الأسعار النهائية لك من المستشفى بعد تحليل ودراسة حالتك من قِبَل المتخصص.</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span dir="LTR" style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><strong><span style='font-size:19px; '>كيف أطلب باقة علاجية من دوكلينيا&nbsp;</span></strong><strong><span dir="LTR" style='font-size:19px; '>&nbsp;</span></strong><strong><span dir="LTR" style='font-size:19px; '>Doclinia</span></strong><strong><span style='font-size:19px; '>؟</span></strong></p>
<ul style="list-style-type: disc;">
    <li><span style=' font-size:14.0pt;'>اضغط على نوع الباقة العلاجية.</span></li>
    <li><span style=' font-size:14.0pt;'>اقرأ بعناية تفاصيل البنود المُدرَجَة والبنود المُستَبعَدة.</span></li>
    <li><span style=' font-size:14.0pt;'>حدد التاريخ المطلوب وعدد الأشخاص.</span></li>
    <li><span style=' font-size:14.0pt;'>
أرسل طلبك من خلال موقعنا للتواصل مع أحد وكلاء خدمة المرضى للتنسيق معك لحجز الباقة المختارة&nbsp;المختارة.</span></li>
</ul>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:justify;'><span style='font-size:19px; '>&nbsp;</span></p>
<p dir="RTL" style='margin:0in;margin-bottom:.0001pt;font-size:15px;;text-align:center;'><span style='font-size:19px; '>**************</span></p>

 </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {},
  computed: {},
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
#faqA p{
  font-family:Arial;
// color: black;
}
#faqA ul{
   font-family:Arial, Helvetica, sans-serif;

}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
   font-family:Arial, Helvetica, sans-serif;
}
</style>


